<template>
    <div :class="containerClasses">
        <div class="flex flex-wrap bg-white rounded-2lg p-2 mts:p-3 mts:mx-2 border-2 border-bordergray hover:border-transparent hover:shadow-searchshadow my-2 md:min-h-[370px] ts:min-h-[425px] 2xl:min-h-[490px]">
            <div class="relative w-full">
                <div class="absolute right-0">
                    <svg width="20" height="20" viewBox="0 0 28.164 23.242" class="cursor-pointer" @click="toogleFavourite" v-if="!favouriteSate">
                        <path
                            id="_23"
                            data-name="23"
                            d="M16.1,27.012a1.412,1.412,0,0,1-.833-.268L4.8,19.034a.734.734,0,0,1-.155-.141A8.833,8.833,0,0,1,16.1,5.507a8.839,8.839,0,0,1,11.522.861h0a8.853,8.853,0,0,1,0,12.525.735.735,0,0,1-.155.141L16.932,26.687A1.412,1.412,0,0,1,16.1,27.012ZM6.5,16.8l9.6,7.06,9.6-7.06a6.043,6.043,0,0,0-.071-8.472h0a6.029,6.029,0,0,0-8.472,0,1.412,1.412,0,0,1-2.005,0A6.056,6.056,0,0,0,6.5,16.8Z"
                            transform="translate(-2.052 -3.77)"
                            fill="#231f20"
                            opacity="0.5"
                        />
                        <path id="Path_50" data-name="Path 50" d="M2605.027,1375.483l3.363-2.4,5.89.491,4.247,2.575v4.546l-.806,4.555-4.806,4.015-7.888,2.158-4.967-3.347-4.8-6.758-.3-4.122,1.56-2.432,4.8-1.19Z" transform="translate(-2593 -1371.54)" fill="#231f20" opacity="0" />
                    </svg>
                    <svg width="20" height="20" viewBox="0 0 28.164 23.242" @click="toogleFavourite" v-if="favouriteSate">
                        <path
                            id="_23"
                            data-name="23"
                            d="M16.1,27.012a1.412,1.412,0,0,1-.833-.268L4.8,19.034a.734.734,0,0,1-.155-.141A8.833,8.833,0,0,1,16.1,5.507a8.839,8.839,0,0,1,11.522.861h0a8.853,8.853,0,0,1,0,12.525.735.735,0,0,1-.155.141L16.932,26.687A1.412,1.412,0,0,1,16.1,27.012ZM6.5,16.8l9.6,7.06,9.6-7.06a6.043,6.043,0,0,0-.071-8.472h0a6.029,6.029,0,0,0-8.472,0,1.412,1.412,0,0,1-2.005,0A6.056,6.056,0,0,0,6.5,16.8Z"
                            transform="translate(-2.052 -3.77)"
                            fill="#eb3b5a"
                        />
                        <path id="Path_50" data-name="Path 50" d="M2605.422,1373.578l3.578,1.19,5.655-1.681,5.6,6.636a23.222,23.222,0,0,1-3.26,5.942,31.3,31.3,0,0,1-4.188,3.753l-5.915,4.255-5.9-5.117-6.04-5.024V1377.2l1.56-2.432,4.8-1.19Z" transform="translate(-2593 -1371.54)" fill="#eb3b5a" />
                    </svg>
                </div>
                <div class="absolute left-0 flex space-x-2" id="discount-badge">
                    <div v-if="product.variants.every((variant) => variant.purchasable === 'in_stock' && variant.stock < 1)" class="bg-minigreen rounded-full text-sx px-2 py-0.5 text-white font-santralextrabold leading-4">Stokta Yok</div>
                    <div v-if="discountRate > 0" class="bg-kbgreen rounded-full text-base px-2 py-0.5 text-white font-santralextrabold leading-4">%{{ discountRate }}</div>
                </div>
            </div>
            <div class="w-9/12 md:w-full mx-auto lg:min-h-[260px]">
                <Link :href="`/urun/${product.default_url?.slug}`" @click="clickProduct">
                    <!--                    <img loading="lazy" :src="product.media_storage[0]?.url" alt="" class="max-h-[267px] ts:min-h-[215px] 2xl:min-h-[266px] align-middle mx-auto w-[267px] h-[267px]" />-->
                    <picture class="flex justify-center">
                        <source :srcset="product.imagesOptimized[0]?.urlThumbWebP" type="image/webp" />
                        <source :srcset="product.imagesOptimized[0]?.urlThumbOrj" type="image/jpeg" />
                        <img :src="product.imagesOptimized[0]?.urlThumbOrj" alt="Alt Text!" />
                    </picture>
                </Link>
            </div>
            <div class="w-full px-1 md:px-3">
                <div class="hidden lg:block text-xs text-center mt-1">
                    <Link :href="`/kategoriler/tum-urunler?filter[brand]=${product.brand?.id}`" class="text-kbgreen font-semibold" @click="clickProduct">
                        {{ product.brand?.name }}
                    </Link>
                </div>
                <div class="text-sm font-semibold mt-1 relative">
                    <div class="max-h-[42px] min-h-[42px] overflow-y-hidden text-[#4d7f71]">
                        <!--                        <Link v-if="product.attribute_data.name.tr.length > 65" :href="`/urun/${product.default_url?.slug}`"> {{ product.attribute_data.name.tr.substring(0, 63) }}...</Link>-->
                        <!--                        <Link v-if="product.attribute_data.name.tr.length < 65" :href="`/urun/${product.default_url?.slug}`">-->
                        <!--                            {{ product.attribute_data.name.tr }}-->
                        <!--                        </Link>-->
                        <Link :class="`short-description`" :href="`/urun/${product.default_url?.slug}`" @click="clickProduct">
                            {{ product.attribute_data.name.tr }}
                        </Link>
                    </div>
                </div>
            </div>
            <!--            <div class="min-h-[28px] w-full block">-->
            <!--                <div class="w-full bg-[#5f4af4] text-white px-4 py-1 text-sm rounded" v-if="product.tags && product.tags.filter((x) => x.id == 5).length == 1">-->
            <!--                    <Link :href="`/urun/${product.default_url?.slug}`" :class="`short-description`" @click="clickProduct"> 6 ay ve üzeri ilk ay 1 TL</Link>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="w-full mt-2 bg-[#5f4af4] text-white px-1 py-2 text-sm" v-if="product.tags && product.tags.filter((x) => x.id == 5).length == 1">3 ay ve üzeri ilk ay 1 TL</div>
            <div class="w-full mt-2">
                <div class="flex text-xs text-center cursor-pointer align-middle items-center h-14 ts:h-14">
                    <ul class="flex border rounded-full bg-white w-full items-center justify-center shadow-xl h-8 ts:h-12">
                        <template v-for="item in subscribetionMonthsOrdered">
                            <li class="text-textgray whitespace-nowrap w-16 h-14 text-xs hover:scale-125 transform duration-300 ease-in-out items-center justify-center flex" :class="[selectedMonth == item[1].subscription_months_id ? selectedClass : '']" @click="selectMonth(item[1].subscription_months_id)" v-if="item[1].price.value">
                                {{ item[1].subscription_months?.name }}
                                <div class="absolute -bottom-1 left-0 lg:left-1" v-if="false">
                                    <div class="bg-kbgreen text-2xs text-white rounded-full px-1 self-center leading-tight">Avantajlı</div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="flex mt-4 lg:mt-2 justify-center w-full tracking-tighter">
                <span class="font-semibold text-base mts:text-lg 2xl:text-xl leading-none">
                    <Link :href="`/urun/${product.default_url.slug}`" @click="clickProduct" class="text-[#4d7f71]">
                        <span class="text-xs 2xl:text-base leading-none mb-1 2xl:mr-2 text-kbgreen line-through whitespace-nowrap" v-if="comparePrice > 0">{{ comparePrice }} TL </span>
                        {{ price }} TL /
                    </Link>
                </span>
                <span class="text-2xs self-center ml-1 leading-none font-santralregular text-[#4d7f71]">
                    <Link :href="`/urun/${product.default_url.slug}`" @click="clickProduct">Aylık ödenecek tutar</Link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import TextClamp from "vue3-text-clamp";

export default {
    data() {
        return {
            selectedMonth: null,
            selectedClass: " shadow-inner shadow-sm border-2 h-14 md:h-14 ts:h-16 rounded-full border-kbgreen p-2 lg:p-4 items-center justify-center flex bg-white !text-[#4d7f71] text-xs hover:!scale-100",
            price: 0,
            comparePrice: 0,
            discountRate: 0,
            favouriteSate: false,
            containerClasses: "w-full mts:w-6/12 lg:w-4/12",
            subscribetionMonthsOrdered: [],
            selectedVariandId: null,
        };
    },
    components: {
        Link,
        TextClamp,
    },
    props: {
        product: Object,
        auth: Object,
        newContainerClasses: {
            type: String,
            value: null,
        },
    },
    methods: {
        selectMonth(month) {
            this.selectedMonth = month;
            this.price = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months_id == month).price.value / 100;
            this.comparePrice = this.product.variants.find((variant) => variant.id == this.selectedVariandId).prices.find((price) => price.subscription_months_id == month).compare_price.value / 100;
            if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
            if (this.comparePrice == 0) this.discountRate = null;
        },
        toogleFavourite() {
            // Kullanıcı login değil ise favori eklemek için login olmalı, yönlendir
            if (this.auth.token == null) {
                this.$inertia.get("/giris-yap");
            }

            window.axios.defaults.headers.common["Authorization"] = `Bearer ${this.auth.token}`;
            axios
                .post(`/favourite/${this.product.id}/toogle`, {
                    productSlug: this.product.default_url.slug,
                })
                .then((res) => {
                    if (res.data.status == "created") {
                        this.favouriteSate = true;
                    } else {
                        this.favouriteSate = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clickProduct() {
            return;
            // Bu satırsan sonrası neden var?
            let cartProducts = [];

            let subscribetionMonthsOrdered = [];
            if (this.product.variants[0]?.prices.length > 0) subscribetionMonthsOrdered = Object.entries(this.product.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
            let nonZeroPrices = this.product.variants[0]?.prices.filter((price) => price.price.value > 0);
            subscribetionMonthsOrdered = subscribetionMonthsOrdered.filter((price) => price[1].price.value > 0);
            let productPrice = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price?.value / 100;

            let product = {};
            product.item_id = this.product.id;
            product.item_name = this.product.attribute_data.name.tr;
            product.price = productPrice;
            product.item_brand = this.product.brand.name;
            product.item_category = this.product.collections[0]?.attribute_data?.name?.tr;
            product.item_category2 = this.product.collections[1]?.attribute_data?.name?.tr ?? "";
            // product.item_comments = item.reviews.total;
            // product.item_rating = item.id;
            // (product.item_color = item.variants[0]?.options[0] ?? ""), (product.index = 0);
            product.discount = 0;
            product.item_variant = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.subscription_months?.name;
            cartProducts.push(product);

            console.log(cartProducts);
            dataLayer.push({
                event: "select_item",
                ecommerce: {
                    item_list_id: "HOME-1",
                    item_list_name: "Homepage - Kampanya Ürünleri List",
                    items: cartProducts,
                },
            });
        },
    },
    mounted() {
        if (this.product?.variants[0]?.prices.length > 0) this.subscribetionMonthsOrdered = Object.entries(this.product?.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
        this.selectedVariandId = this.product.variants[0].id;
        let nonZeroPrices = this.product.variants[0].prices.filter((price) => price.price.value > 0);
        this.subscribetionMonthsOrdered = this.subscribetionMonthsOrdered.filter((price) => price[1].price.value > 0);
        this.price = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price?.value / 100;
        this.comparePrice = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.compare_price?.value / 100;
        if (this.comparePrice > 0) this.discountRate = Math.round(100 - (100 * this.price) / this.comparePrice);
        if (this.comparePrice == 0) this.discountRate = null;

        this.selectedMonth = this.subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.subscription_months?.id;
        this.favouriteSate = this.product.favourite;
        if (this.newContainerClasses != undefined) {
            this.containerClasses = this.newContainerClasses;
        }
    },
};
</script>

<style scoped></style>
